import { get, post, put, Delete } from "../axios";

// 解决方案-列表
export const infoSolutionList = (params) =>
  get("/web/info/solution/list", params);

// 解决方案-新增
export const infoSolutionAdd = (params) =>
  post("/web/info/solution/add", params);

// 解决方案-编辑
export const infoSolutionUpdate = (params) =>
  put("/web/info/solution/update", params);

// 解决方案-删除
export const infoSolutionDelete = (id) =>
  Delete("/web/info/solution/delete/" + id, "");

// 解决方案-详情
export const infoSolutionView = (id) =>
  get("/web/info/solution/view/" + id, "");

// 解决方案-首页推荐-状态修改
export const infoSolutionHomeUpdate = (params) =>
  put("/web/info/solution/home/update", params);
